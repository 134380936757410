import { pick } from 'lodash-es'
import type { ActiveTierId } from 'd2/reducers/StorageTier'
import type {
  AppliedToAllAttributesEnum,
  Composer,
  DefaultMetadata,
  File,
  NewFile,
  UploadingFile,
} from 'd2/reducers/UploadMedia'
import type { BannerKey } from 'd2/reducers/Banner'
import type { DestinationType } from 'd2/reducers/DestinationPicker'
import type { LimitedBetaFeature } from 'd2/reducers/LimitedBeta'
import type { MobileNavBarMenus } from 'd2/reducers/MobileNavBar'
import type { ModalKey, ModalState } from 'd2/reducers/Modal'
import type { MutationError } from 'd2/types'
import type { Namespace } from 'd2/reducers/ValidationErrors'
import type {
  NetworkExtra,
  ScheduleType,
  Social,
} from 'd2/reducers/CreateRelease/types'
import type { SelectedNetworkIds } from 'd2/reducers/CreateAlbumRelease'
import type { Payload as SnackbarPayload } from 'd2/reducers/Snackbar'
import type { State } from 'd2/reducers'
import type { StorageConsumptionTableSortColumns } from 'd2/reducers/Ui'
import type { FormType as UclaFormType, FormValues as UclaFormValues } from 'd2/reducers/Ucla/types'

type SelectedValues = string[]

export type Action = {
  payload: {
    accordion: string
  },
  type: 'SET_ACTIVE_ACCORDION'
} | {
  payload: {
    activeTierId: ActiveTierId
  },
  type: 'SELECT_TIER'
} | {
  payload: {
    appliedToAll: boolean,
    attribute: AppliedToAllAttributesEnum,
    fileIndex: number
  },
  type: 'UPLOAD_MEDIA_UPDATE_APPLIED_TO_ALL'
} | {
  payload: {
    artistDefaultMetadata: DefaultMetadata
  },
  type: 'UPLOAD_MEDIA_UPDATE_DEFAULT_METADATA'
} | {
  payload: {
    artistDefaultMetadata: DefaultMetadata
  },
  type: 'UPLOAD_MEDIA_UPDATE_FILE_WITH_DEFAULT_METADATA'
} | {
  payload: {
    artworkImagePath: string
  },
  type: 'CREATE_ALBUM_ADD_IMAGE_PATH'
} | {
  payload: {
    composer: Composer | null | undefined
  },
  type: 'UPLOAD_MEDIA_ADD_COMPOSER'
} | {
  payload: {
    deployAt: Scalar$DateTime
  },
  type: 'CREATE_ALBUM_RELEASE_UPDATE_DEPLOY_AT'
} | {
  payload: {
    deployAt: Scalar$DateTime
  },
  type: 'SCHEDULE_SELECTOR_UPDATE_DEPLOY_AT'
} | {
  payload: {
    deployAt: Scalar$DateTime | null | undefined,
    scheduleType: ScheduleType | null | undefined
  },
  type: 'CREATE_RELEASE_SET_DEPLOY_AT'
} | {
  payload: {
    description: string | null | undefined
  },
  type: 'CREATE_RELEASE_DESCRIPTION'
} | {
  payload: {
    descriptionChanged: boolean
  },
  type: 'CREATE_RELEASE_SET_DESCRIPTION_CHANGED'
} | {
  payload: {
    destinationType: DestinationType
  },
  type: 'DESTINATION_SELECTOR_UPDATE_DESTINATION_TYPE'
} | {
  payload: {
    errorReason: string,
    fileIndex: number,
  },
  type: 'UPLOAD_MEDIA_ERROR'
} | {
  payload: {
    errors: Array<MutationError>,
    namespace: Namespace
  },
  type: 'ADD_VALIDATION_ERRORS'
} | {
  payload: {
    feature: LimitedBetaFeature
  },
  type: 'HIDE_LIMITED_BETA'
} | {
  payload: {
    feature: LimitedBetaFeature
  },
  type: 'SHOW_LIMITED_BETA'
} | {
  payload: {
    fileIndex: number
  },
  type: 'UPLOAD_MEDIA_REMOVE_FILE'
} | {
  payload: {
    fileMetadata: File
  },
  type: 'UPLOAD_MEDIA_UPDATE_METADATA'
} | {
  payload: {
    files: Array<File>
  },
  type: 'UPLOAD_MEDIA_SET_VALIDATED_FLAGS'
} | {
  payload: {
    files: Array<NewFile>
  },
  type: 'UPLOAD_MEDIA_ADD_FILES'
} | {
  payload: {
    formType: UclaFormType
  },
  type: 'UCLA_SELECT_FORM_TYPE'
} | {
  payload: {
    formValues: UclaFormValues
  },
  type: 'UCLA_UPDATE_FORM'
} | {
  payload: {
    imageThumbnailUrl: string
  },
  type: 'CREATE_ALBUM_ADD_THUMBNAIL_URL'
} | {
  payload: {
    itemIds: SelectedValues,
    prefix: string
  },
  type: 'DESELECT_ITEMS'
} | {
  payload: {
    itemIds: SelectedValues,
    prefix: string
  },
  type: 'SELECT_ITEMS'
} | {
  payload: {
    key: BannerKey
  },
  type: 'HIDE_BANNER'
} | {
  payload: {
    key: BannerKey
  },
  type: 'SHOW_BANNER'
} | {
  payload: {
    key: ModalKey
  },
  type: 'HIDE_MODAL'
} | {
  payload: {
    key: ModalKey,
    state: ModalState
  },
  type: 'SHOW_MODAL'
} | {
  payload: {
    mediaId: string
  },
  type: 'CREATE_ALBUM_ADD_SELECTED_MEDIA'
} | {
  payload: {
    namespace: Namespace
  },
  type: 'CLEAR_VALIDATION_ERRORS'
} | {
  payload: {
    networkExtras: NetworkExtra[]
  },
  type: 'CREATE_RELEASE_SELECT_NETWORK_EXTRAS'
} | {
  payload: {
    networkIds: SelectedValues
  },
  type: 'CREATE_RELEASE_SELECT_NETWORK'
} | {
  payload: {
    ownExclusiveRights: boolean | null | undefined
  },
  type: 'UPLOAD_MEDIA_UPDATE_OWN_EXCLUSIVE_RIGHTS'
} | {
  payload: {
    prefix: string
  },
  type: 'DESELECT_ALL'
} | {
  payload: {
    scheduleType: ScheduleType
  },
  type: 'SCHEDULE_SELECTOR_UPDATE_SCHEDULE_TYPE'
} | {
  payload: {
    scheduleType: ScheduleType | null | undefined
  },
  type: 'CREATE_ALBUM_RELEASE_UPDATE_SCHEDULE_TYPE'
} | {
  payload: {
    selectedArtistId: string
  },
  type: 'CREATE_ALBUM_SELECT_ARTIST'
} | {
  payload: {
    selectedArtistId: string
  },
  type: 'SET_SELECTED_UPLOAD_ARTIST'
} | {
  payload: {
    selectedFileIndex: number
  },
  type: 'UPLOAD_MEDIA_SELECT_FILE_INDEX'
} | {
  payload: {
    selectedMediaId: string
  },
  type: 'CREATE_RELEASE_SELECT_MEDIA'
} | {
  payload: {
    selectedNetworkIds: SelectedNetworkIds
  },
  type: 'CREATE_ALBUM_RELEASE_UPDATE_SELECTED_NETWORK_IDS'
} | {
  payload: {
    selectedNetworkIds: SelectedNetworkIds
  },
  type: 'DESTINATION_SELECTOR_UPDATE_SELECTED_NETWORK_IDS'
} | {
  payload: {
    socials: Social[]
  },
  type: 'CREATE_RELEASE_SELECT_SOCIALS'
} | {
  payload: {
    successPath: string
  },
  type: 'UPLOAD_MEDIA_SET_SUCCESS_PATH'
} | {
  payload: {
    uploadingFiles: Array<UploadingFile>
  },
  type: 'UPLOAD_MEDIA_SYNC_UPLOADING_FILES'
} | {
  payload: {
    width: number | null | undefined
  },
  type: 'SET_DEVICE_DIMENSIONS'
} | {
  payload: MobileNavBarMenus,
  type: 'CLOSE_MOBILE_NAV_BAR_MENU'
} | {
  payload: MobileNavBarMenus,
  type: 'OPEN_MOBILE_NAV_BAR_MENU'
} | {
  payload: number,
  type: 'CHANGE_STORAGE_CONSUMPTION_TABLE_PAGE'
} | {
  payload: SnackbarPayload,
  type: 'SHOW_SNACKBAR'
} | {
  payload: StorageConsumptionTableSortColumns,
  type: 'CHANGE_STORAGE_CONSUMPTION_TABLE_SORT'
} | {
  type: 'CANCEL_SELECT_ALL_CONTENT'
} | {
  type: 'CLOSE_MOBILE_NAV_BAR_DRAWER'
} | {
  type: 'CREATE_ALBUM_RELEASE_RESET'
} | {
  type: 'CREATE_ALBUM_RESET'
} | {
  type: 'CREATE_RELEASE_RESET'
} | {
  type: 'DESTINATION_SELECTOR_RESET'
} | {
  type: 'HEADER_STICK'
} | {
  type: 'HEADER_UNSTICK'
} | {
  type: 'HIDE_SNACKBAR'
} | {
  type: 'INCREMENT_COUNTER_2'
} | {
  type: 'INCREMENT_COUNTER'
} | {
  type: 'OPEN_MOBILE_NAV_BAR_DRAWER'
} | {
  type: 'SCHEDULE_SELECTOR_RESET'
} | {
  type: 'SELECT_ALL_CONTENT'
} | {
  type: 'UCLA_RESET_FORM'
} | {
  type: 'UPLOAD_MEDIA_INCREMENT_FILE_INDEX'
} | {
  type: 'UPLOAD_MEDIA_REMOVE_ALL_FILES'
} | {
  type: 'UPLOAD_MEDIA_RESET'
}

/* eslint-disable no-use-before-define */
export type Dispatch = (a: Action | Array<Action> | PromiseAction | ThunkAction) => Action
export type ThunkAction = (b: Dispatch, a: GetState) => void
/* eslint-enable no-use-before-define */
export type PromiseAction = Promise<Action>
export type GetState = () => State
export type MapStateToProps<StateProps, OwnProps = any> = (b: State, a: OwnProps) => StateProps
export type MapDispatchToProps<DispatchProps, OwnProps = any> = (b: Dispatch, a: OwnProps) => DispatchProps

export const incrementCounter: () => Action = () => ({ type: 'INCREMENT_COUNTER' })
export const incrementCounter2: () => Action = () => ({ type: 'INCREMENT_COUNTER_2' })

export const addValidationErrors: (b: Namespace, a: Array<MutationError>) => Action = (namespace, errors) => ({
  payload: {
    errors,
    namespace,
  },
  type: 'ADD_VALIDATION_ERRORS',
})

export const clearValidationErrors: (a: Namespace) => Action = (namespace) => ({
  payload: {
    namespace,
  },
  type: 'CLEAR_VALIDATION_ERRORS',
})

export const showSnackbar: (a: SnackbarPayload) => Action = (payload) => ({
  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  payload: pick(payload, [
    'actionPressAction',
    'actionTargetBlankLocation',
    'actionText',
    'd1RedirectLocation',
    'd2RedirectLocation',
    'hasDismiss',
    'heading',
    'message',
    'persist',
    'type',
  ]) as SnackbarPayload,
  type: 'SHOW_SNACKBAR',
})

export const hideSnackbar: () => Action = () => ({ type: 'HIDE_SNACKBAR' })

export const selectTier: (a: ActiveTierId) => Action = (activeTierId) => ({
  payload: {
    activeTierId,
  },
  type: 'SELECT_TIER',
})

export const showModal: (b: ModalKey, a?: ModalState | null) => Action = (key, state) => ({
  payload: {
    key,
    state: state ?? {},
  },
  type: 'SHOW_MODAL',
})

export const hideModal: (a: ModalKey) => Action = (key) => ({
  payload: {
    key,
  },
  type: 'HIDE_MODAL',
})

export const showLimitedBeta: (a: LimitedBetaFeature) => Action = (feature) => ({
  payload: {
    feature,
  },
  type: 'SHOW_LIMITED_BETA',
})

export const hideLimitedBeta: (a: LimitedBetaFeature) => Action = (feature) => ({
  payload: {
    feature,
  },
  type: 'HIDE_LIMITED_BETA',
})

export const headerStick: () => Action = () => ({
  type: 'HEADER_STICK',
})

export const headerUnstick: () => Action = () => ({
  type: 'HEADER_UNSTICK',
})

export const deselectItems: (b: string, a: Array<string>) => Action = (prefix, itemIds) => ({
  payload: {
    itemIds,
    prefix,
  },
  type: 'DESELECT_ITEMS',
})

export const selectItems: (b: string, a: Array<string>) => Action = (prefix, itemIds) => ({
  payload: {
    itemIds,
    prefix,
  },
  type: 'SELECT_ITEMS',
})

export const deselectAll: (a: string) => Action = (prefix) => ({
  payload: {
    prefix,
  },
  type: 'DESELECT_ALL',
})

export const uploadMediaIncrementFileIndex: () => Action = () => ({
  type: 'UPLOAD_MEDIA_INCREMENT_FILE_INDEX',
})

export const uploadMediaSelectFileIndex: (a: number) => Action = (selectedFileIndex) => ({
  payload: {
    selectedFileIndex,
  },
  type: 'UPLOAD_MEDIA_SELECT_FILE_INDEX',
})

export const uploadMediaSetSuccessPath: (a: string) => Action = (successPath) => ({
  payload: {
    successPath,
  },
  type: 'UPLOAD_MEDIA_SET_SUCCESS_PATH',
})

export const uploadMediaSetValidatedFlags: (a: Array<File>) => Action = (files) => ({
  payload: {
    files,
  },
  type: 'UPLOAD_MEDIA_SET_VALIDATED_FLAGS',
})

export const uploadMediaAddFiles: (a: Array<NewFile>) => Action = (files) => ({
  payload: {
    files,
  },
  type: 'UPLOAD_MEDIA_ADD_FILES',
})

export const uploadMediaError: (a: number, b?: string) => Action = (fileIndex, errorReason = '') => ({
  payload: {
    errorReason,
    fileIndex,
  },
  type: 'UPLOAD_MEDIA_ERROR',
})

export const uploadMediaRemoveFile: (a: number) => Action = (fileIndex: number) => ({
  payload: {
    fileIndex,
  },
  type: 'UPLOAD_MEDIA_REMOVE_FILE',
})

export const uploadMediaRemoveAllFiles: () => Action = () => ({
  type: 'UPLOAD_MEDIA_REMOVE_ALL_FILES',
})

export const uploadMediaUpdateAppliedToAll: (
  a: {
    appliedToAll: boolean,
    attribute: AppliedToAllAttributesEnum,
    fileIndex: number
  }
) => Action = ({
  appliedToAll,
  attribute,
  fileIndex,
}) => ({
  payload: {
    appliedToAll,
    attribute,
    fileIndex,
  },
  type: 'UPLOAD_MEDIA_UPDATE_APPLIED_TO_ALL',
})

export const uploadMediaSyncUploadingFiles: (a: Array<UploadingFile>) => Action = (uploadingFiles) => ({
  payload: {
    uploadingFiles,
  },
  type: 'UPLOAD_MEDIA_SYNC_UPLOADING_FILES',
})

export const uploadMediaUpdateMetadata: (a: File) => Action = (fileMetadata) => ({
  payload: {
    fileMetadata,
  },
  type: 'UPLOAD_MEDIA_UPDATE_METADATA',
})

export const uploadMediaUpdateOwnExclusiveRights: (a?: boolean | null) => Action = (ownExclusiveRights) => ({
  payload: {
    ownExclusiveRights,
  },
  type: 'UPLOAD_MEDIA_UPDATE_OWN_EXCLUSIVE_RIGHTS',
})

export const uploadMediaUpdateDefaultMetadata: (a: DefaultMetadata) => Action = (artistDefaultMetadata) => ({
  payload: {
    artistDefaultMetadata,
  },
  type: 'UPLOAD_MEDIA_UPDATE_DEFAULT_METADATA',
})

export const uploadMediaUpdateFileWithDefaultMetadata: (a: DefaultMetadata) => Action = (artistDefaultMetadata) => ({
  payload: {
    artistDefaultMetadata,
  },
  type: 'UPLOAD_MEDIA_UPDATE_FILE_WITH_DEFAULT_METADATA',
})

export const uploadMediaReset: () => Action = () => ({
  type: 'UPLOAD_MEDIA_RESET',
})

export const createAlbumAddImagePath: (a: string) => Action = (artworkImagePath) => ({
  payload: {
    artworkImagePath,
  },
  type: 'CREATE_ALBUM_ADD_IMAGE_PATH',
})

export const createAlbumAddThumbnailUrl: (a: string) => Action = (imageThumbnailUrl) => ({
  payload: {
    imageThumbnailUrl,
  },
  type: 'CREATE_ALBUM_ADD_THUMBNAIL_URL',
})

export const createAlbumReset: () => Action = () => ({
  type: 'CREATE_ALBUM_RESET',
})

export const createAlbumSelectMedia: (a: string) => Action = (mediaId) => ({
  payload: {
    mediaId,
  },
  type: 'CREATE_ALBUM_ADD_SELECTED_MEDIA',
})

export const createAlbumSelectArtist: (a: string) => Action = (selectedArtistId) => ({
  payload: {
    selectedArtistId,
  },
  type: 'CREATE_ALBUM_SELECT_ARTIST',
})

export const setSelectedArtist: (a: string) => Action = (selectedArtistId) => ({
  payload: {
    selectedArtistId,
  },
  type: 'SET_SELECTED_UPLOAD_ARTIST',
})

export const createReleaseReset: () => Action = () => ({
  type: 'CREATE_RELEASE_RESET',
})

export const createAlbumReleaseReset: () => Action = () => ({
  type: 'CREATE_ALBUM_RELEASE_RESET',
})

export const createAlbumReleaseUpdateDeployAt: (a: Scalar$DateTime) => Action = (deployAt) => ({
  payload: {
    deployAt,
  },
  type: 'CREATE_ALBUM_RELEASE_UPDATE_DEPLOY_AT',
})

export const createAlbumReleaseUpdateScheduleType: (a?: ScheduleType | null) => Action = (scheduleType) => ({
  payload: {
    scheduleType,
  },
  type: 'CREATE_ALBUM_RELEASE_UPDATE_SCHEDULE_TYPE',
})

export const createAlbumReleaseUpdateSelectedNetworkIds: (a: SelectedNetworkIds) => Action = (selectedNetworkIds) => ({
  payload: {
    selectedNetworkIds,
  },
  type: 'CREATE_ALBUM_RELEASE_UPDATE_SELECTED_NETWORK_IDS',
})

export const createReleaseDescription: (a?: string | null) => Action = (description) => ({
  payload: {
    description,
  },
  type: 'CREATE_RELEASE_DESCRIPTION',
})

export const createReleaseSelectMedia: (a: string) => Action = (selectedMediaId) => ({
  payload: {
    selectedMediaId,
  },
  type: 'CREATE_RELEASE_SELECT_MEDIA',
})

export const createReleaseSelectNetwork: (a: string[]) => Action = (networkIds) => ({
  payload: {
    networkIds,
  },
  type: 'CREATE_RELEASE_SELECT_NETWORK',
})

export const createReleaseSelectSocials: (a: Social[]) => Action = (socials) => ({
  payload: {
    socials,
  },
  type: 'CREATE_RELEASE_SELECT_SOCIALS',
})

export const createReleaseSelectNetworkExtras: (a: NetworkExtra[]) => Action = (networkExtras) => ({
  payload: {
    networkExtras,
  },
  type: 'CREATE_RELEASE_SELECT_NETWORK_EXTRAS',
})

export const createReleaseSetDeployAt: (b?: Scalar$DateTime | null, a?: ScheduleType | null) => Action = (deployAt, scheduleType) => ({
  payload: {
    deployAt,
    scheduleType,
  },
  type: 'CREATE_RELEASE_SET_DEPLOY_AT',
})

export const createReleaseDescriptionChanged: () => Action = () => ({
  payload: {
    descriptionChanged: true,
  },
  type: 'CREATE_RELEASE_SET_DESCRIPTION_CHANGED',
})

export const destinationPickerReset: () => Action = () => ({
  type: 'DESTINATION_SELECTOR_RESET',
})

export const destinationPickerUpdateDestinationType: (a: DestinationType) => Action = (destinationType) => ({
  payload: {
    destinationType,
  },
  type: 'DESTINATION_SELECTOR_UPDATE_DESTINATION_TYPE',
})

export const destinationPickerUpdateSelectedNetworkIds: (a: SelectedNetworkIds) => Action = (selectedNetworkIds) => ({
  payload: {
    selectedNetworkIds,
  },
  type: 'DESTINATION_SELECTOR_UPDATE_SELECTED_NETWORK_IDS',
})

export const scheduleSelectorReset: () => Action = () => ({
  type: 'SCHEDULE_SELECTOR_RESET',
})

export const scheduleSelectorUpdateScheduleType: (a: ScheduleType) => Action = (scheduleType) => ({
  payload: {
    scheduleType,
  },
  type: 'SCHEDULE_SELECTOR_UPDATE_SCHEDULE_TYPE',
})

export const scheduleSelectorUpdateDeployAt: (a: Scalar$DateTime) => Action = (deployAt) => ({
  payload: {
    deployAt,
  },
  type: 'SCHEDULE_SELECTOR_UPDATE_DEPLOY_AT',
})

export const setActiveAccordion: (a: string) => Action = (accordion) => ({
  payload: {
    accordion,
  },
  type: 'SET_ACTIVE_ACCORDION',
})

export const uclaSelectFormType: (a: UclaFormType) => Action = (formType) => ({
  payload: {
    formType,
  },
  type: 'UCLA_SELECT_FORM_TYPE',
})

export const uclaUpdateForm: (a: UclaFormValues) => Action = (formValues) => ({
  payload: {
    formValues,
  },
  type: 'UCLA_UPDATE_FORM',
})

export const setDeviceDimensions: (
  a: {
    width: number | null | undefined
  }
) => Action = (dimensions) => ({
  payload: dimensions,
  type: 'SET_DEVICE_DIMENSIONS',
})

export const uclaResetForm: () => Action = () => ({
  type: 'UCLA_RESET_FORM',
})

export const cancelSelectAllContent: () => Action = () => ({
  type: 'CANCEL_SELECT_ALL_CONTENT',
})
